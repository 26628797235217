import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { ThemeProvider } from 'styled-components';

const LocaleContext = React.createContext();

const gridProl = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 1, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 76  // rem
    },
    breakpoints: {
      xs: 0,  // em
      sm: 48, // em
      md: 64, // em
      lg: 75  // em
    }
  }
}

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout = ({ children, pageContext: { locale } }) => (
  <LocaleContext.Provider value={{ locale }}>
    <ThemeProvider theme={gridProl}>
      <div className={locale}>
        <Header />
      </div>
      <main role="main">
        {children}
      </main>
      <Footer />
    </ThemeProvider>
  </LocaleContext.Provider>
);

export { Layout, LocaleContext };
