import React from 'react';
import { Link } from 'gatsby';
import useTranslations from '../useTranslations';
import TitlePage from '../TitlePage';
import Logo from '../Logo';
import Languages from '../Languages';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

const Header = () => {

  const {
    hello,
    home
  } = useTranslations();

  return (
    <Grid>
      <header className="site-header">
        <Row className="site-header__row" top="xs">
          <Col md={12} sm={12} xs={12}>
            <div className='site-header__logo'>
              <Link to="/" title={home} aria-label={home}>
                <Row middle="xs">
                  <Col md={2} xs={2}>
                    <Logo />
                  </Col>
                  <Col md={10} xs={10}>
                      <TitlePage text={hello} />
                  </Col>
                </Row>
              </Link>
            </div>
          </Col>
          {/* <Col md={2} sm={12} xs={12}>
            <div className="site-header__lang">
              <Languages />  
            </div>  
          </Col> */}
        </Row>
      </header>
    </Grid>
  );
};

export default Header;
