import React from 'react';
import useTranslations from '../useTranslations';

const TitlePage = props => {
  const { text } = props;
  const {
    subline,
  } = useTranslations();

  return (
    <header className="site-name">
      <h1 className="site-name__title">{text}</h1>
      <div className="site-name__subtitle">{subline}</div>
    </header>
  );
};

export default TitlePage;
