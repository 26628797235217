import React from 'react';
import { Link } from 'gatsby';

const Languages = () => {
  return (
    <ul className="lang-menu">
      <li className="lang-menu__item">
        <Link to="/" className="lang-menu__gl"  hrefLang="gl">
          En galego
        </Link>
      </li>
      <li className="lang-menu__item">
        <Link to="/en" className="lang-menu__en" hrefLang="en">
          In English
        </Link>
      </li>
    </ul>
  );
};

export default Languages;
