import React, { useState } from 'react';
import useTranslations from '../useTranslations';
import LocalizedLink from '../LocalizedLink';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

const Footer = () => {
  const {
    footer1,
    footer2,
    footer3,
    footer4,
  } = useTranslations();

  return (
    <Grid>
      <footer className="site-footer">
        <Row end="xs">
          <Col>
            <div className="site-footer__text">
              {footer1} <a href="https://www.gatsbyjs.org/">{footer2}</a> {footer3} <LocalizedLink to="/about">{footer4}</LocalizedLink>
            </div>
          </Col>
        </Row>
      </footer>
    </Grid>
  );
};

export default Footer;
